$primary-color: #ff9f1a;
$background-color: #F6F6F6;

body {
  background-color: $background-color;
}

#navbar {
  transition: top 0.2s;
  background-color: $primary-color;
}

.header {
  margin-bottom: 61px;
}

.bike p {
  display: table-row;
}

.bike span {
  display: table-cell
}

footer {
  padding: 50px;
  background-color: #ff9f1a;
  margin-top: 50px;
}

.search {
  padding: 20px 0;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 1.25rem;
  height: 1.25rem;
}

table, td, th {
  background-color: white;
  margin: 0;
  padding: 5px;
  border: 1px solid $background-color;

  td:first-child {
    background-color: rgba(0, 0, 0, .075);
  }

  img {
    max-width: 150px;
  }
}

.bike {
  .row {
    margin: 0 0;

    .specs-item {
      border-bottom: 1px solid $background-color;
      padding: 0;

      dt {
        padding: 0;
      }

      dd {
        padding: 0;
        @media screen and (max-width: 576px) {
          text-align: right;
        }
      }
    }
  }
}

.bike-list, .search-result, .collection {
  padding: 0;

  .card {
    margin: 10px 0;

    h5 {
      @media screen and (max-width: 576px) {
        font-size: 16px;
      }
    }

    a {
      padding: 10px;
    }

    label {
      font-size: 16px;
    }
  }
}

.download-card {
  .card-img-container {
    padding: 20px;

    .logo {
      padding: 30px;
      background-color: $primary-color;
      border-radius: 15px;
    }
  }

  .google-play-btn {
    img {
      max-width: 200px;
    }
  }
}

.add-to-compare {
  display: flex;
  justify-content: center;
  align-items: center;
}

.compare-body {
  a {
    color: $primary-color !important;
  }
}

.scroll-horizontal {
  overflow-x: auto;
  white-space: nowrap;

  img {
    width: 100%;
    max-width: 200px;
  }
}